import React, { FC, useEffect, useRef, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  FontSizes,
  IButtonStyles,
  mergeStyles,
  PrimaryButton,
  Stack,
  Text,
} from "@fluentui/react";
import { Document, Project } from "../../models";
import Loader from "../../components/Loader";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import {
  getDocumentversions,
  getPrecedentDocuments,
} from "../../modules/redux/Documents/document";
import {
  getAllProjectDetails,
  getAllProjects,
  getWorkingGroup,
} from "../../modules/redux/Projects/project";
import "./index.css";
import { ProjectDetailsCard } from "../../components/Cards/ProjectDetailsCard";
import { DocsInProject } from "./DocsInProject";
import FadeTransition from "../../components/FadeTransition";
import { VerticalStack } from "../../components/Stack";
import DragAndDropComp from "./dragAndDrop";
import { colorPallete } from "../../assets/color";
import Help from "../../components/Help";
import { setDocumentWithVersions } from "../../modules/redux/DocumentVersionSlice";
import { getSortedArrayMRU } from "../../utils";
import WelcomeModal from "../../components/modals/WelcomeModal";
import { ApiClient } from "../../network/ApiClient";
import { ApiEndpoints } from "../../network/ApiEndpoints";
import useModal from "../../hooks/useModal";

const DocumentPage: FC = () => {
  const { t } = useTranslation();
  let { projectId } = useParams();
  const projectIdRef = useRef(projectId);
  const location = useLocation();
  const [params, setParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const showProfileModal = () => setIsProfileModalOpen(true);
  const hideProfileModal = () => setIsProfileModalOpen(false);
  const { isModalOpen, showModal, hideModal } = useModal();
  const [rootDocumentId,setRootDocumentId] = useState(0);

  const welcomeUser = useAppSelector((state) => state.WelcomeUser.value);

  const projectState = useAppSelector(
    (state: { fetchProjectDetails: any }) => state.fetchProjectDetails
  );
  const [projectData, setProjectData] = useState<Project | null>(null);
  const [permsission, setPermission] = useState<boolean>(false);
  const [folderName, setFolderName] = useState("");
  const created = location.state && location.state.created && "open";


  useEffect(() => {
    if (welcomeUser === "redirect" || welcomeUser === "close") {
      setIsProfileModalOpen(false);
      const updateFTL = async () => {
        let response = await ApiClient.put<any, any>(
          ApiEndpoints.UpdateFirstTimeLogin(),
          {}
        );
        localStorage.setItem("user", JSON.stringify(response?.data));
        window.location.replace(`/home/${projectId}`);
      };
      updateFTL();
    }
  }, [welcomeUser]);

  useEffect(() => {
    const ftlValue = params.get("ftl");
    const userData = async () => {
      try {
        const response = await ApiClient.get<any>(ApiEndpoints.userDetails());
        return response.data;
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    userData().then((user: any) => {
      const ftl = user?.dataDictionary?.isFirstLogin;
      if (ftl === false && ftlValue) {
        window.location.replace(`/home/${projectId}`);
      } else if (ftl === true && welcomeUser === "newUser") {
        setIsProfileModalOpen(true);
      }
    });
  }, [projectId, params]);

  useEffect(() => {
    if (projectState.data) {
      setProjectData(projectState.data);
    }
  }, [projectState.isLoading]);

  useEffect(() => {
    setFolderName(projectData?.projectName!);
  }, [projectData?.projectName]);

  useEffect(() => {
    if (projectId) {
      dispatch(getAllProjectDetails(parseInt(projectId, 10)));
      dispatch(
        getAllProjects({
          limit: 3000,
          offset: 0,
        })
      );
      dispatch(getWorkingGroup(parseInt(projectId, 10)));
    }
  }, []);

  function refreshData() {
    projectId && dispatch(getAllProjectDetails(parseInt(projectId, 10)));
    dispatch(
      getPrecedentDocuments({
        limit: 30,
        offset: 0,
      })
    );
    dispatch(
      getAllProjects({
        limit: 3000,
        offset: 0,
      })
    );
  }

  const projectErrorJSX = projectState.isLoading ? (
    <Stack className={mergeStyles(styles.loader)}>
      <Stack style={{ margin: "2%" }}>
        <Loader />
      </Stack>
      <Text
        className={mergeStyles({
          fontSize: FontSizes.size32,
          fontFamily: "Poppins",
        })}
      >
        {t("loading")}
      </Text>
    </Stack>
  ) : !projectState.isLoading && projectState.error !== "" ? (
    <Stack className={mergeStyles(styles.loader)}>
      <Text className={mergeStyles(styles.textStyles)}>{t("loader")}</Text>
      <PrimaryButton
        styles={retryButtonStyles}
        onClick={() => dispatch(getAllProjectDetails(Number(projectId!)))}
      >
        {t("retry")}
      </PrimaryButton>
    </Stack>
  ) : null;


  return (
    <FadeTransition>
      {permsission ? (
        <Stack className={mergeStyles(styles.loader)}>
          <Stack style={{ margin: "2%" }}>
            <Loader />
          </Stack>
          <Text
            className={mergeStyles({
              fontSize: FontSizes.size32,
              fontFamily: "Poppins",
            })}
          >
            {t("loading")}
          </Text>
        </Stack>
      ) : projectErrorJSX ? (
        projectErrorJSX
      ) : projectState.data && projectData?.projectName ? (
        <>
          <VerticalStack style={{ ...styles.container, position: "relative" }}>
            <ProjectDetailsCard projectData={projectData} />
            <WelcomeModal
              isModalOpen={isProfileModalOpen}
              hideModal={hideProfileModal}
              showModal={showProfileModal}
            />
            <div className="LightGreyCard">
              <DragAndDropComp
                showImportModal ={showModal}  
                hideImportModal = {hideModal}
                isImportModalOpen={isModalOpen}
                created={created}
                projectId={projectData?.projectId!}
                refresh={refreshData}
                folderName={folderName}
                rootDocumentId={rootDocumentId}
                setRootDocumentId={setRootDocumentId}
              />
              <DocsInProject
                projectData={projectData}
                refresh={refreshData}
                showImportModal ={showModal}
                hideImportModal = {hideModal}
                isImportModalOpen={isModalOpen}
                setRootDocumentId={setRootDocumentId}
              />
            </div>
            <Help />
          </VerticalStack>
        </>
      ) : (
        <Stack
          style={{
            width: "100%",
            alignItems: "center",
            alignSelf: "center",
            marginTop: "10%",
            height: "380px",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              fontSize: "24px",
              lineHeight: "36px",
              fontWeight: 600,
              fontFamily: "Poppins",
              color: colorPallete.black1,
            }}
          >
            {typeof projectState.data === "string" && projectState.data}
          </Text>
        </Stack>
      )}
    </FadeTransition>
  );
};
export default DocumentPage;
const retryButtonStyles: IButtonStyles = {
  root: {
    width: "20px",
    margin: "1%",
    alignItems: "center",
  },
};
const styles = {
  container: {
    width: "100%",
    alignItems: "center",
    overflow: "hidden",
    marginTop: "1rem",
    backgroundColor: "",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    paddingTop: "10%",
  },
  textStyles: {
    fontSize: FontSizes.size24,
    fontFamily: "Poppins",
  },
};
