import {
  FC,
  useState,
  useCallback,
  useEffect,
  useMemo,
  FormEvent,
} from "react";
import { FormModal } from "../Modal";
import { HorizontalStack, VerticalStack } from "../../Stack";
import { BtFileObject } from "../../../models";
import { useTranslation } from "react-i18next";
import {
  FontIcon,
  Label,
  Link,
  mergeStyles,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import { useDropzone } from "react-dropzone";
import Loader from "../../Loader";
import {
  createTemplate,
  createTemplateVersion,
  getPrecedentDocuments,
} from "../../../modules/redux/Documents/document";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import {
  getAllProjectDetails,
  getAllProjects,
} from "../../../modules/redux/Projects/project";
import { TextForBody, TextForPageHeaders } from "../../Text";
import DocumentList from "../../Cards/DocumentList";
import { colorPallete } from "../../../assets/color";
import UploadDocumentIcon from "../../../assets/Icons/svg/uploadDocumentIcon";
import CustomDropdown from "../../CustomDropDown";
import WordDocIcon from "../../../assets/Icons/svg/wordDocIcon";
import "./index.css";
import Buttons from "./components/buttons";
import AddNewVersion from "./components/addNewVersion";
import useWebSocket from "react-use-websocket";
import { Providers } from "@microsoft/mgt-element";
import ProgressBar from "../../CustomProgressIndicator";
import { getSortedArrayMRU } from "../../../utils";

export const CreateDocumentModal: FC<any> = (props) => {
  const [folderName, setFolderName] = useState("");
  const [fileNameError, setFileNameError] = useState(false);
  const [modal, setModal] = useState<boolean>(false);
  const [upload, setUpload] = useState<boolean>(false);
  const [msg, setMsg] = useState<string>("");
  const [showResponse, setShowResponse] = useState<boolean>(false);
  const [responseData, setResponseData] = useState<any>([]);
  const [nameError, setNameError] = useState(false);
  const [newVersion, setNewVersion] = useState(false);
  const [addDocument, setAddDocument] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [error1, setError] = useState(false);
  const [folderNameError, setFolderNameError] = useState<boolean>(false);
  const [projectData, setProjectData] = useState<any>([]);
  const [docsData, setDocsData] = useState<Document[]>([]);
  const [documentName, setDocumentName] = useState<string>("");
  const [documentNameError, setDocumentNameError] = useState<boolean>(false);
  const [rootDocumentId, setRootDocumentId] = useState<any>(0);
  const [projectId, setProjectId] = useState<any>(0);
  const [multipleFilesError, setMultipleFilesError] = useState<boolean>(false);
  const [documentItem, setDocumentItem] = useState<Document | any>();
  const [newFileName, setNewFileName] = useState(
    props.document?.rootDocumentName
  );

  useEffect(() => {
    setRootDocumentId(props.rootDocumentId);
    if (props.rootDocumentId && props.rootDocumentId !== 0) {
      setNewVersion(true);
    }
  }, [props.rootDocumentId]);
  const { isLoading, data, error, metadata } = useAppSelector(
    (state: any) => state.fetchProjects
  );

  const [singleFileError, setSingleFileError] = useState<boolean>(false);

  const [shouldConnect, setShouldConnect] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [currentProgress, setCurrentProgress] = useState(0);
  const [totalProgress, setTotalProgress] = useState(0);
  const [messageHistory, setMessageHistory] = useState<MessageEvent<any>[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [socketUrl, setSocketUrl] = useState("");
  useEffect(() => {
    const fetchAccessToken = async () => {
      const token = await Providers.globalProvider.getAccessToken();
      setAccessToken(token);
    };
    fetchAccessToken();
  }, [accessToken]);

  const connectWebSocket = () => {
    setSocketUrl(
      `${
        process.env.REACT_APP_SOCKET_URL
      }/upload/files?projectId=${projectId}&rootDocumentId=${
        !newVersion ? 0 : rootDocumentId ?? null
      }&isNewVersion=${newVersion}`
    );
    setShouldConnect(true);
  };
  function _arrayBufferToBase64(buffer: any) {
    var binary = "";
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  const { sendMessage, sendJsonMessage, lastMessage } = useWebSocket(
    socketUrl,
    {
      onOpen: () => startUpload(),
      onClose: () => console.log("closed"),
      protocols: ["realProtocol", accessToken],
    },
    shouldConnect
  );

  useEffect(() => {
    if (lastMessage !== null) {
      if (
        lastMessage.data.trim().startsWith("[") &&
        lastMessage.data.trim().endsWith("]")
      ) {
        try {
          let parsedData = JSON.parse(lastMessage.data);
          setMsg("Success");
          props.setMultipleFiles([]);
          setUpload(true);
          setResponseData(parsedData);
          setShowResponse(true);
          dispatch(
            getPrecedentDocuments({
              limit: 21,
              offset: 0,
            })
          );
          dispatch(
            getAllProjects({
              limit: 30,
              offset: 0,
            })
          );
          setTimeout(() => {
            redirectFolder(projectId);
            disable();
          }, 2000);
        } catch (error) {
          console.log(
            "Error parsing JSON, treating as a regular string:",
            lastMessage
          );
        }
      }
      setMessageHistory((prev) => prev.concat(lastMessage));
      // setCurrentProgress((currentProgress) => currentProgress + 1);
      setCurrentProgress(parseInt(lastMessage.data))
    }
  }, [lastMessage]);

  const startUpload = () => {
    var files: File[] = props.multipleFiles.map(
      (file: BtFileObject) => file.file
    );
    handleFileUpload(files);
  };

  const SendMessageOnWebSocket = (message: any) =>
    sendMessage(JSON.stringify(message));

  const handleFileUpload = async (selectedFiles: any) => {
    const readerPromises = selectedFiles.map((file: any) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      });
    });
    Promise.all(readerPromises)
      .then((buffers) => {
        if (buffers.length > 0) {
          let totalDocumentsChunks = 0;
          buffers.forEach((fileBuffer, index) => {
            const totalSize = props.multipleFiles[index].file.size;
            const chunkSize = 1024 * 1024;
            const totalChunks = Math.ceil(totalSize / chunkSize);
            totalDocumentsChunks += totalChunks;
            setTotalProgress((totalProgress) => totalProgress + totalChunks);
          });
          buffers.forEach((fileBuffer, index) => {
            const totalSize = props.multipleFiles[index].file.size;
            const chunkSize = 1024 * 1024;
            const totalChunks = Math.ceil(totalSize / chunkSize);
            let chunkNumber = 0;
            let start = 0;
            let end = Math.min(chunkSize, totalSize);

            while (chunkNumber < totalChunks) {
              end = Math.min(end, totalSize);
              const chunk = fileBuffer.slice(start, end);
              const base64Chunk = _arrayBufferToBase64(chunk);
              const formData = {
                ChunkNumber: chunkNumber,
                TotalChunks: totalChunks,
                FileName: props.multipleFiles[index].fileName,
                ChunkData: base64Chunk,
                TotalProgress: totalDocumentsChunks,
                TotalDocuments: buffers.length,
              };
              SendMessageOnWebSocket(formData);
              chunkNumber++;
              start = end;
              end = Math.min(start + chunkSize, totalSize);
            }
          });
          //sendJsonMessage({ closeConnection: true });
        }
      })
      .catch((error) => console.error("Error reading file:", error));
  };

  useEffect(() => {
    if (data && data.length > 0) {
      setProjectData(data);
    } else {
      setProjectData([]);
    }
  }, [data, metadata]);

  useEffect(() => {
    if (props?.folderName && props?.projectId) {
      setFolderName(props?.folderName);
      setProjectId(props?.projectId);
      setSocketUrl(
        `${process.env.REACT_APP_SOCKET_URL}/upload/files?projectId=${
          props?.projectId
        }&rootDocumentId=${rootDocumentId ?? null}&isNewVersion=${newVersion}`
      );
    }
  }, [props.folderName, props?.projectId]);

  useEffect(() => {
    documentItem?.rootDocumentId &&
      setRootDocumentId(documentItem.rootDocumentId);
    let updated = projectData.filter(
      (item: any) => item.projectName === folderName
    );
    if (updated[0]?.projectId) {
      setProjectId(updated[0]?.projectId);
      setSocketUrl(
        `${process.env.REACT_APP_SOCKET_URL}/upload/files?projectId=${
          updated[0]?.projectId
        }&rootDocumentId=${rootDocumentId ?? null}&isNewVersion=${newVersion}`
      );
    }
    setDocumentItem(null);
    if (updated[0]?.documents?.length > 0) {
      let sortedArray = getSortedArrayMRU(updated[0]?.documents);
      setDocsData(sortedArray);
    } else {
      setDocsData([]);
    }
  }, [folderName, projectData, documentItem]);

  useEffect(() => {
    if (nameError && props.multipleFiles) {
      let check: boolean = false;
      props.multipleFiles.forEach((btFile: BtFileObject) => {
        if (btFile.fileName.length === 0) check = true;
      });
      setNameError(check);
    }
    if (props.multipleFiles && props.multipleFiles.length > 0) {
      setMultipleFilesError(false);
      setError(false);
      setSingleFileError(false);
    }
  }, [props.multipleFiles]);

  const redirectFolder = (projectId: number | undefined) => {
    if (props?.projectId && props?.projectId === projectId) {
      window.location.reload();
    } else {
      window.location.href = `/home/${projectId}`;
    }
  };

  function addMultipleFiles(files: File[]) {
    setFileNameError(false);
    let incomingFiles: BtFileObject[] = [];
    files.forEach((fObject: File) => {
      incomingFiles.push({
        fileName: fObject.name,
        file: fObject,
      });
    });
    props.setMultipleFiles((prevFiles: BtFileObject[]) => {
      let result = [...prevFiles, ...incomingFiles];
      const uniqueArr = result.filter(
        (value, index, array) =>
          array.findIndex((curr) => value.fileName === curr.fileName) === index
      );
      return uniqueArr;
    });
    setMultipleFilesError(false);
    setSingleFileError(false);
  }

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({
    noDragEventsBubbling: true,
    multiple: true,
    onDrop: (acceptedFiles) => {
      addMultipleFiles(acceptedFiles);
    },
  });

  const style = useMemo(
    () => ({
      ...(isFocused ? styles.focusedStyle : {}),
      ...(isDragAccept ? styles.acceptStyle : {}),
      ...(isDragReject ? styles.rejectStyle : {}),
      ...{ height: "100%", width: "100%" },
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  function checkValidation(value?: any) {
    var check: boolean = false;
    props.multipleFiles.forEach((btFile: BtFileObject) => {
      if (btFile.fileName.length === 0) {
        check = true;
      }
    });
    if (String(folderName).length === 0) {
      setFolderNameError(true);
      return;
    }
    if (newVersion && String(documentName).length === 0) {
      setDocumentNameError(true);
      return;
    }
    if (props.multipleFiles.length === 0) {
      setError(true);
      return;
    }
    if (check) {
      setNameError(true);
      return;
    }
    if (
      !newVersion &&
      !error1 &&
      String(folderName).length !== 0 &&
      props.multipleFiles.length > 0
    ) {
      return true;
    }
    if (
      newVersion &&
      !error1 &&
      String(folderName).length !== 0 &&
      props.multipleFiles.length !== 1 &&
      !confirm
    ) {
      setSingleFileError(true);
    } else if (
      newVersion &&
      !error1 &&
      String(folderName).length !== 0 &&
      props.multipleFiles.length === 1 &&
      value !== "confirmed"
    ) {
      setAddDocument(true);
      return true;
    }
  }

  const copyDocument = () => {
    if (String(folderName).length === 0) {
      setFolderNameError(true);
      return;
    }
    if (newVersion && String(documentName).length === 0) {
      setDocumentNameError(true);
      return;
    }
    if (!newVersion && String(folderName).length !== 0) {
      let obj = {
        projectId: projectId,
        driveItemId: props?.document.driveItemId,
        newFileName: newFileName,
      };
      setModal(true);
      dispatch(createTemplate(obj))
        .then((res: any) => {
          setMsg("Success");
          setUpload(true);
          setResponseData([res?.payload]);
          setShowResponse(true);
          setTimeout(() => {
            dispatch(
              getPrecedentDocuments({
                limit: 21,
                offset: 0,
              })
            );
            dispatch(
              getAllProjects({
                limit: 30,
                offset: 0,
              })
            );
            dispatch(getAllProjectDetails(projectId)).then(() => {
              redirectFolder(projectId);
            });
            disable();
          }, 2000);
        })
        .catch((res: any) => {
          setUpload(true);
          res ? setMsg(res) : setMsg("upload_failed");
          setTimeout(() => {
            setUpload(false);
            setModal(false);
            disable();
          }, 2000);
        });
    } else if (
      newVersion &&
      String(folderName).length !== 0 &&
      String(documentName).length !== 0
    ) {
      let obj = {
        rootDocumentId: rootDocumentId,
        driveItemId: props?.document.driveItemId,
      };
      setModal(true);
      dispatch(createTemplateVersion(obj))
        .then((res: any) => {
          setMsg("Success");
          setUpload(true);
          setResponseData([res?.payload]);
          setShowResponse(true);
          setTimeout(() => {
            dispatch(
              getPrecedentDocuments({
                limit: 21,
                offset: 0,
              })
            );
            dispatch(
              getAllProjects({
                limit: 30,
                offset: 0,
              })
            );
            dispatch(getAllProjectDetails(projectId)).then(() => {
              redirectFolder(projectId);
            });
            disable();
          }, 2000);
        })
        .catch((res: any) => {
          setUpload(true);
          res ? setMsg(res) : setMsg("upload_failed");
          setTimeout(() => {
            setUpload(false);
            setModal(false);
            disable();
          }, 2000);
        });
    }
  };
  function handleCLick(data?: string) {
    if (props?.copy) {
      return copyDocument();
    } else {
      let value = checkValidation();
      if (value && !newVersion) {
        setModal(true);
        connectWebSocket();
      } else if (newVersion && value && data === "confirmed") {
        setModal(true);
        connectWebSocket();
      }
    }
  }

  function disable() {
    setModal(false);
    setError(false);
    !props.copy && props.setMultipleFiles && props.setMultipleFiles([]);
    props.hideModal();
    setFileNameError(false);
    setNameError(false);
    setFileNameError(false);
    setAddDocument(false);
    setNewVersion(false);
    setMsg("");
    setUpload(false);
    setSingleFileError(false);
    setDocumentItem(null);
    setNewFileName(props.document?.rootDocumentName);
    setFolderName(props?.folderName);
    setProjectId(props?.projectId);
    props?.setRootDocumentId && props?.setRootDocumentId(0);
  }

  const Response = (data: any) => {
    return (
      <Stack className={mergeStyles(styles.responseContainer)}>
        {data.map((res: any) => {
          const code = res.code !== undefined ? res.code : res.Code; // Accessing code property regardless of case
          const message = res.message !== undefined ? res.message : res.Message; // Accessing message property regardless of case
          window.clarity("event", "Document Uploaded");
          return code === 201 || code === 200 ? (
            <Stack className={mergeStyles(styles.success)} key={message}>
              <Stack style={{ alignItems: "center", paddingRight: "15px" }}>
                <FontIcon
                  iconName="SkypeCircleCheck"
                  style={{
                    fontSize: "25px",
                    backgroundColor: "white",
                    color: colorPallete.btGreen,
                  }}
                />
              </Stack>
              <Stack>
                <TextForBody className={mergeStyles(styles.successMessage)}>
                  {message}
                  {/* {t("document_imported")} */}
                </TextForBody>
              </Stack>
            </Stack>
          ) : (
            <Stack className={mergeStyles(styles.failed)} key={message}>
              <Stack style={{ alignItems: "center", paddingRight: "15px" }}>
                <FontIcon
                  iconName="StatusErrorFull"
                  style={{
                    fontSize: "25px",
                    backgroundColor: colorPallete.white,
                    color: colorPallete.btRed,
                  }}
                />
              </Stack>
              <Stack>
                <TextForBody className={mergeStyles(styles.errorMessage)}>
                  {message} :{" "}
                  {code === 208
                    ? t("already_exists")
                    : code === 404
                    ? t("file_empty")
                    : code === 500 && t("upload_fail")}
                </TextForBody>
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    );
  };

  const onChangeFileNameTextField = useCallback(
    (
      event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      setNewFileName(newValue || "");
    },
    []
  );

  function getBackgroundColor() {
    if (props.copy && !newVersion && String(folderName).length > 0) {
      return `${colorPallete.btBlue}`;
    } else if (
      props.copy &&
      newVersion &&
      String(folderName).length > 0 &&
      String(documentName).length > 0
    ) {
      return `${colorPallete.btBlue}`;
    } else if (
      !newVersion &&
      String(folderName).length > 0 &&
      props.multipleFiles &&
      props.multipleFiles.length > 0
    ) {
      return `${colorPallete.btBlue}`;
    } else if (
      newVersion &&
      String(folderName).length > 0 &&
      props.multipleFiles &&
      props.multipleFiles.length == 1 &&
      String(documentName).length > 0
    ) {
      return `${colorPallete.btBlue}`;
    } else {
      return "#EAEAEA";
    }
  }

  function getFontColor() {
    if (props.copy && !newVersion && String(folderName).length > 0) {
      return `${colorPallete.white}`;
    } else if (
      props.copy &&
      newVersion &&
      String(folderName).length > 0 &&
      String(documentName).length > 0
    ) {
      return `${colorPallete.white}`;
    } else if (
      !newVersion &&
      String(folderName).length > 0 &&
      props.multipleFiles &&
      props.multipleFiles.length > 0
    ) {
      return `${colorPallete.white}`;
    } else if (
      newVersion &&
      String(folderName).length > 0 &&
      props.multipleFiles &&
      props.multipleFiles.length === 1 &&
      String(documentName).length > 0
    ) {
      return `${colorPallete.white}`;
    } else {
      return "#717070";
    }
  }
  const ErrorJSX = (props: any) => {
    return (
      <Stack
        style={{ width: "100%", alignItems: "flex-end", marginTop: "4px" }}
      >
        <Text
          style={{
            ...styles.mediumText,
            color: colorPallete.btRed,
          }}
        >
          {props?.message}
        </Text>
      </Stack>
    );
  };
  return (
    <FormModal
      title={props.copy ? t("copy_precedent") : t("import_documents")}
      TitleBackgroundColor={"#18468F"}
      isModalOpen={props.isModalOpen}
      showModal={props.showModal}
      hideModal={props.hideModal}
      fileName={props.fileName}
      width="610px"
      cancelButton
      customCancel={disable}
    >
      {modal ? (
        <Stack className={mergeStyles(styles.uploadModal)}>
          {upload ? (
            showResponse ? (
              Response(responseData)
            ) : (
              <>
                <TextForPageHeaders>{t(msg)}</TextForPageHeaders>
              </>
            )
          ) : (
            <Stack style={styles.loader}>
              {props.copy ? (
                <>
                  <Stack style={{ padding: "2%" }}>
                    <Loader />
                  </Stack>
                  <TextForPageHeaders>{t("copying_docs")}</TextForPageHeaders>
                </>
              ) : (
                <ProgressBar
                  progress={currentProgress}
                  total={totalProgress}
                  isSuccess={isSuccess}
                />
              )}
            </Stack>
          )}
        </Stack>
      ) : (
        <>
          {addDocument ? (
            <AddNewVersion
              documentName={documentName}
              folderName={folderName}
              addDocument={addDocument}
              setAddDocument={setAddDocument}
              multipleFiles={props.multipleFiles}
              rootDocumentId={rootDocumentId}
              handleCLick={handleCLick}
              setDocumentName={setDocumentName}
            />
          ) : (
            <>
              <VerticalStack style={styles.container}>
                <Text style={styles.headingText}>
                  {props?.copy ? t("copy_as") : t("import_as")}
                </Text>
                <Buttons
                  newVersion={newVersion}
                  multipleFilesError={multipleFilesError}
                  setNewVersion={setNewVersion}
                  files={props.multipleFiles && props.multipleFiles.length}
                  setMultipleFilesError={setMultipleFilesError}
                  copy={props?.copy}
                />
                {multipleFilesError ? (
                  <ErrorJSX message={t("error_import_v")} />
                ) : null}
                {!newVersion ? (
                  <VerticalStack
                    style={{ marginTop: "28px", minHeight: "237px" }}
                  >
                    <HorizontalStack className="projectName">
                      <Label style={styles.headingText} required>
                        {t("project_name")}
                      </Label>
                      <Text
                        onClick={() => {
                          props.project();
                        }}
                        style={styles.blueText}
                      >
                        + {t("new_project")}
                      </Text>
                    </HorizontalStack>
                    <CustomDropdown
                      options={projectData.map((item: any) => item.projectName)}
                      title={t("project_dd")}
                      data={setFolderName}
                      error={setFolderNameError}
                      folderName={folderName}
                    />
                    {folderNameError ? (
                      <ErrorJSX message={t("error_project")} />
                    ) : null}
                    {props?.copy ? (
                      <HorizontalStack
                        style={{
                          ...styles.documentCard,
                          marginTop: "28px",
                        }}
                      >
                        <WordDocIcon />
                        <TextField
                          borderless
                          id="fileName"
                          value={newFileName}
                          onChange={onChangeFileNameTextField}
                          style={styles.documentText}
                        />
                      </HorizontalStack>
                    ) : (
                      <VerticalStack style={{ marginBottom: "28px" }}>
                        <Stack
                          style={{
                            ...styles.baseStyle,
                            backgroundColor: error1 ? " #F6EAE7" : "#F8F8F8",
                            marginTop: "28px",
                          }}
                        >
                          <div {...getRootProps({ style })}>
                            <input {...getInputProps()} />
                            <Stack
                              style={{
                                flexDirection:
                                  props.multipleFiles.length > 0
                                    ? "row"
                                    : "column",
                                alignItems: "center",
                                gap: "8px",
                                justifyContent: "center",
                                padding:
                                  props.multipleFiles.length > 0
                                    ? "7px 0px"
                                    : "15px 0px",
                              }}
                            >
                              <UploadDocumentIcon
                                height={
                                  props.multipleFiles.length > 0
                                    ? "16px"
                                    : "34px"
                                }
                                width={
                                  props.multipleFiles.length > 0
                                    ? "15px"
                                    : "33px"
                                }
                              />
                              <HorizontalStack>
                                <Text style={styles.dragText}>
                                  {t("drag_drop")}
                                </Text>
                                <Link
                                  style={{
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                    color: colorPallete.btBlue,
                                    fontFamily: "Poppins",
                                  }}
                                  underline
                                >
                                  {t("browse")}
                                </Link>
                              </HorizontalStack>
                            </Stack>
                          </div>
                        </Stack>
                        {props.multipleFiles.length === 0 && error1 ? (
                          <ErrorJSX message={t("error_document")} />
                        ) : (
                          <DocumentList
                            multipleFiles={props.multipleFiles}
                            setMultipleFiles={props.setMultipleFiles}
                          />
                        )}
                      </VerticalStack>
                    )}
                    {nameError ? (
                      <ErrorJSX message={t("error_filename")} />
                    ) : null}
                  </VerticalStack>
                ) : (
                  <VerticalStack
                    style={{ marginTop: "28px", minHeight: "237px" }}
                  >
                    <HorizontalStack
                      style={{
                        marginBottom: "4px",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Label style={styles.headingText} required>
                        {t("project_name")}
                      </Label>
                    </HorizontalStack>
                    <CustomDropdown
                      options={projectData.map((item: any) => item.projectName)}
                      title={t("project_dd")}
                      data={setFolderName}
                      error={setFolderNameError}
                      folderName={folderName}
                    />
                    {folderNameError ? (
                      <ErrorJSX message={t("error_project")} />
                    ) : null}
                    {String(folderName).length > 0 && docsData.length > 0 ? (
                      <VerticalStack style={{ marginTop: "28px" }}>
                        <Label
                          required
                          style={{ ...styles.headingText, marginBottom: "4px" }}
                        >
                          {t("document_family")}
                        </Label>
                        <CustomDropdown
                          options={docsData}
                          title={t("document_dd")}
                          data={setDocumentName}
                          isDocumentDropdown={true}
                          folderName={folderName}
                          error={setDocumentNameError}
                          documentId={setRootDocumentId}
                          rootDocumentId={rootDocumentId}
                          documentItem={documentItem}
                          setDocumentItem={setDocumentItem}
                        />
                        {documentNameError ? (
                          <ErrorJSX message={t("error_docfamily")} />
                        ) : null}
                      </VerticalStack>
                    ) : null}
                    {props.copy ? (
                      <HorizontalStack
                        style={{
                          ...styles.documentCard,
                          marginTop: "28px",
                          marginBottom: "28px",
                        }}
                      >
                        <WordDocIcon />
                        <Text style={styles.documentText}>
                          {props.document.rootDocumentName}
                        </Text>
                      </HorizontalStack>
                    ) : props.multipleFiles.length > 0 ? (
                      <Stack
                        style={{ marginTop: "28px", marginBottom: "28px" }}
                      >
                        <DocumentList
                          multipleFiles={props.multipleFiles}
                          setMultipleFiles={props.setMultipleFiles}
                          newVersion={newVersion}
                        />
                        {singleFileError ? (
                          <ErrorJSX message={t("error_import_v")} />
                        ) : null}
                      </Stack>
                    ) : (
                      <VerticalStack>
                        <Stack
                          style={{
                            ...styles.baseStyle,
                            backgroundColor: error1 ? " #F6EAE7" : "#F8F8F8",
                            marginTop: "28px",
                          }}
                        >
                          <div {...getRootProps({ style })}>
                            <input {...getInputProps()} />
                            <HorizontalStack style={styles.inputWrapper}>
                              <UploadDocumentIcon height="16px" width="15px" />
                              <HorizontalStack>
                                <Text style={styles.dragText}>
                                  {t("drag_drop")}
                                </Text>
                                <Link
                                  style={{
                                    fontSize: "0.8rem",
                                    fontWeight: "400",
                                    color: colorPallete.btBlue,
                                    fontFamily: "Poppins",
                                  }}
                                  underline
                                >
                                  {t("browse")}
                                </Link>
                              </HorizontalStack>
                            </HorizontalStack>
                          </div>
                        </Stack>
                        {props.multipleFiles.length === 0 && error1 ? (
                          <ErrorJSX message={t("error_document")} />
                        ) : null}
                      </VerticalStack>
                    )}
                  </VerticalStack>
                )}
              </VerticalStack>
              <Stack className="horizontalLine"></Stack>
              <HorizontalStack style={styles.bottom}>
                <Text
                  style={{
                    ...styles.addButton,
                    ...styles.mediumText,
                    fontWeight: "500",
                    background: getBackgroundColor(),
                    color: getFontColor(),
                    textAlign: "center",
                  }}
                  onClick={() => handleCLick("")}
                >
                  {props.copy ? t("copy") : t("import")}
                </Text>
                <Text
                  style={{
                    ...styles.cancelButton,
                    ...styles.mediumText,
                    textAlign: "center",
                    fontWeight: "500",
                  }}
                  onClick={disable}
                >
                  {t("cancel")}
                </Text>
              </HorizontalStack>
            </>
          )}
        </>
      )}
    </FormModal>
  );
};

export const styles = {
  container: {
    paddingLeft: "25px",
    paddingRight: "25px",
    backgroundColor: "white",
    paddingTop: "30px",
  },
  headingText: {
    color: "#1F2430",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    fontStyle: "normal",
  },
  mediumText: {
    color: "#323232",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "16px",
    fontStyle: "normal",
  },
  documentText: {
    color: "#323232",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "16px",
    fontStyle: "normal",
    background: "#F8F8F8",
    width: "470px",
  },
  button: {
    width: "49%",
    height: "40px",
    backgroundColor: "#F8F8F8",
    alignItems: "center",
    borderRadius: "5px",
    cursor: "pointer",
  },
  blueText: {
    color: `${colorPallete.btBlue}`,
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "24px",
    fontStyle: "normal",
    cursor: "pointer",
  },
  dropDown: {
    marginBottom: "28px",
    minWidth: "75%",
  },
  addButton: {
    padding: "12px 24px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "4px",
    cursor: "pointer",
    width: "120px",
  },
  cancelButton: {
    padding: "12px 24px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "4px",
    backgroundColor: "#E8ECF4",
    color: "#323232",
    cursor: "pointer",
    width: "120px",
  },
  bottom: {
    padding: "20px 25px",
    alignSelf: "flex-end",
    gap: "9px",
    justifyContent: "flex-end",
  },
  dragText: {
    paddingRight: "0.3rem",
    fontSize: "0.8rem",
    fontWeight: "400",
    color: colorPallete.grey1,
    fontFamily: "Poppins",
  },
  greyCircle: {
    width: "19px",
    height: "19px",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "15px",
    marginRight: "4px",
  },
  blueCircle: {
    width: "11px",
    height: "11px",
    borderRadius: "50%",
    backgroundColor: "#18468F",
    alignSelf: "center",
  },
  versionCard: {
    padding: "8px 16px",
    borderRadius: "4px",
    border: `1px solid ${colorPallete.btLightBlue}`,
    backgroundColor: "white",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "10px",
    width: "98%",
    minHeight: "60px",
  },
  versionContainer: {
    width: "91.5%",
    borderRadius: "8px",
    border: "1px solid #EAEAEA",
    backgroundColor: "#F8F8F8",
    alignItems: "center",
    gap: "12px",
    padding: "12px",
    alignSelf: "flex-end",
  },
  projectDetails: {
    alignItems: "center",
    gap: "10px",
  },
  projectText: {
    color: "#323232",
    fontFamily: "Poppins",
    fontSize: "17px",
    fontWeight: "500",
    lineHeight: "18px",
    fontStyle: "normal",
  },
  dateText: {
    color: "#323232",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "16px",
    fontStyle: "normal",
  },
  loader: {
    padding: "2%",
    backgroundColor: "",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "center",
    marginTop: "1%",
  },
  inputWrapper: {
    alignItems: "center",
    gap: "8px",
    justifyContent: "center",
    padding: "7px 0px",
  },
  versionHistory: {
    backgroundColor: "white",
    padding: "20px 25px",
  },
  responseContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: colorPallete.white,
    height: "100%",
    padding: "15px",
  },
  success: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: colorPallete.white,
    paddingBottom: "2%",
  },
  failed: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: colorPallete.white,
    paddingBottom: "2%",
  },
  baseStyle: {
    backgroundColor: "#F8F8F8",
    borderRadius: "8px",
    padding: "10px",
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `repeating-linear-gradient(1deg, #51596C, #51596C 11px, transparent 11px, transparent 25px, #51596C 25px), repeating-linear-gradient(91deg, #51596C, #51596C 11px, transparent 11px, transparent 25px, #51596C 25px), repeating-linear-gradient(181deg, #51596C, #51596C 11px, transparent 11px, transparent 25px, #51596C 25px), repeating-linear-gradient(271deg, #51596C, #51596C 11px, transparent 11px, transparent 25px, #51596C 25px)`,
    backgroundSize: "1px 100%, 100% 1px, 1px 100% , 100% 1px",
    backgroundPosition: "0 0, 0 0, 100% 0, 0 100%",
    backgroundRepeat: "no-repeat",
    display: "flex",
    marginBottom: "8px",
  },
  focusedStyle: {
    borderColor: "#2196f3",
  },
  acceptStyle: {
    borderColor: "#0E4394",
  },
  rejectStyle: {
    borderColor: "#ff1744",
  },
  uploadModal: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    minHeight: "8rem",
    alignItems: "center",
    padding: "1%",
    backgroundColor: colorPallete.white,
  },
  formDataStyles: {
    paddingTop: "1.5%",
    backgroundColor: "",
    overflowY: "auto",
  },
  errorMessage: {
    color: colorPallete.btRed,
    fontSize: "14px",
    fontfamily: "Poppins",
  },
  successMessage: {
    color: colorPallete.btGreen,
    fontSize: "14px",
    fontfamily: "Poppins",
  },
  documentCard: {
    width: "100%",
    padding: "6px 16px",
    gap: "16px",
    borderRadius: "5px",
    border: "1px solid #EAEAEA",
    background: "#F8F8F8",
    alignItems: "center",
  },
};
